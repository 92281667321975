import React from "react";
import { graphql, Link } from "gatsby"
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Container, Typography, Box, Link as MuiLink } from "@material-ui/core";

import Layout from '../components/Layout';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        padding: theme.spacing(2, 0),

        '& p, li': {
          fontSize: 16,
        },
        '& a': {
          textDecoration: 'underline',
        }
      },
    }
  ),
);

interface Props {
  data: {
    site: {
      siteMetadata: {
        shortName: string;
      }
    }
  }
}

const TermsPage: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  const name = data.site.siteMetadata.shortName;

  return (
    <Layout contentClassName={classes.root} title="Terms and Conditions">
      <Container maxWidth="md">
        <Typography align="center" variant="h4">Terms and Conditions</Typography>
        <br />
        <strong>Last updated: 1/1/2020.</strong>
        <p>By accessing or using this website, any of its pages and/or any of the services referenced herein, you accept and agree to be bound by the Terms of Service set forth below.</p>

        <Box>
          <strong>1. ACCEPTANCE OF TERMS.</strong>
          <p>{name} ({name},” “we,” “us,” or “our”) provides users with an online automotive information and communications platform, as well as related services that can be accessed from or through this website (collectively, “Services”). Please carefully read these Terms of Service before using the Services. By accessing or using the Services, including this website, you accept and agree to be bound by these Terms of Service (“Terms of Service”) and all applicable laws, rules, and regulations associated with your use of the Services. If you do not agree to the Terms of Service, you are not authorized to use this website or the Services. These Terms of Service also apply to any co-branded or framed version of this website.</p>
          <p>Your use of certain materials and features of this website and/or the Services may be subject to additional terms and conditions which are incorporated herein by reference and become part of the Terms of Service. By using those materials and features, you also agree to be bound by such additional terms and conditions. Unless explicitly stated otherwise, any new features that augment or enhance the current Services shall be subject to the Terms of Service.</p>
          <p>This website is controlled and operated by {name} from its offices within the United States. {name} makes no representation that information or materials available on this website are appropriate or available for use in other locations, and access to this website from territories where its contents are illegal is prohibited. Those who choose to access this website from other locations do so at their own initiative and are responsible for compliance with applicable local laws.</p>
        </Box>


        <Box>
          <strong>2. USER CONDUCT</strong>
          <p>You are authorized by {name} to access and use the Services, including the information on this website, solely for your personal, non-commercial use provided that you are at least 18 years of age. The information and materials displayed on this website may not otherwise be copied, transmitted, displayed, distributed, downloaded, licensed, modified, published, posted, reproduced, used, sold, transmitted, used to create a derivative work, or otherwise used for commercial or public purposes without {name}’s express prior written consent. Any use of data mining, robots or similar data gathering or extraction tools or processes in connection with this website, and any reproduction or circumvention of the navigational structure or presentation of this website or its content, is strictly prohibited. You agree not to use the Services, including this website, for any unlawful purpose.</p>
          <p>In order to access certain features of this website you may be required to register. You are responsible for maintaining the confidentiality of your password and account and are fully responsible for all activities that occur under your password or account. You agree to immediately notify {name} in writing of any unauthorized use of your password or account or any other breach of security, and ensure that you exit from your account at the end of each session. {name} is not liable for any loss or damage arising from your failure to comply with this section. You agree not to modify the Services or use modified versions of the Services (except if modified by {name}), including for the purpose of obtaining unauthorized access to the Services. You agree not to access the Services by any means other than through the interface that is provided by {name} for use in accessing the Services.</p>
        </Box>

        <Box>
          <strong>3. PRIVACY</strong>
          <p>{name} is committed to respecting your privacy and protecting your personally identifiable information.
          Upon your request, {name} shares the information you enter on this website with participating dealers and/or buyers in order to provide you with the information you requested, and with other service providers associated with the Services.
          {name} account data and certain other information about you and that you enter and/or we collect through your use of this website are subject to the {name} Privacy Policy <MuiLink component={Link} to="/privacy">here</MuiLink>. You understand that through your use of the Services, including this website, you consent to the collection and use (as set forth in the {name} Privacy Policy) of this information. Please review our Privacy Policy for further information on our data collection and use practices.</p>
        </Box>
        
        <Box>
          <strong>4. SERVICES</strong>
          <p>All information provided on this website is for informational purposes only. Information displayed through the Services related to any virtual vehicle you may configure, such as dealer cost, factory invoice, market average, MSRP, or Estimated Savings, either reflects or is based on available data relevant to your virtual vehicle and does not reflect a dealer's price for an actual vehicle consistent with your preferences. Any savings information that you may receive from a selling dealer or user, such as Estimated Member Savings, Estimated Member Price, Guaranteed Savings, Member Savings, Certified Dealer MSRP Discount, Total Dealer Fees and Accessories, Total Before Tax, or Estimated Dealer Price is provided directly to you by the selling dealer or user, and is not provided by {name}. Neither the accuracy of information provided on this website, nor the availability, quality, or safety of vehicles, is guaranteed or controlled by {name}, and {name} assumes no responsibility for the foregoing. You agree that any reliance on the information on this website is at your own risk. Any discrepancies or mistakes made regarding vehicle availability, condition, pricing, and the like are not the responsibility of {name} and should be directed to the dealer or third party vendor. You are encouraged to thoroughly review any documents you are asked to sign at the time of purchase or lease of a vehicle or of other products or services. We do invite you to bring to our attention any material on our website that you believe to be inaccurate; please forward a copy of such material and your reasons for your belief <MuiLink component={Link} to="/contact-us">here</MuiLink>.</p>
          <p>By using the Services, you acknowledge and agree that participating dealers may not have in inventory a new vehicle that exactly matches any virtual vehicle you may configure on the website, and certain models or configurations may not be available. Your dealer will confirm vehicle availability, including available options and colors, from actual inventory. Each dealer sets and controls its own pricing. You may negotiate the purchase price directly with the dealer, and {name} plays no role in that negotiation. The savings information communicated directly to you by a selling dealer for a vehicle consistent with the preferences expressed in any virtual vehicle you may configure may change if the actual in-stock vehicle that you choose to buy differs from the configuration of your virtual vehicle. Used vehicles are subject to prior sale.</p>
          <p>For the avoidance of doubt, {name} is solely a research and communications platform. {name} is not a vehicle seller, dealer, broker or agent for vehicle sellers or dealers, nor a provider of, or broker or agent for, other automotive-related products or services offered by third parties. {name} receives a fee from the participating dealers or third-party service providers in connection with the Services.</p>
        </Box>

        <Box>
          <strong>5. NO COMMERCIAL USE OF SERVICES</strong>
          <p>You agree not to reproduce, duplicate, copy, sell, trade, resell or exploit for any commercial purposes, any portion or use of, or access to, the Services.</p>
        </Box>

        <Box>
          <strong>6. RIGHT TO DENY ACCESS AND TO MODIFY THE SERVICES</strong>
          <p>{name} reserves the right to deny use of, or access to, the Services to you and/or anyone for any or no reason. {name} also reserves the right at any time and from time-to-time to modify or discontinue, temporarily or permanently, the Services (or any part thereof) with or without notice. You agree that {name} shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Services (or any part thereof).</p>
        </Box>

        <Box>
          <strong>7. INDEMNIFICATION</strong>
          <p>You will indemnify, defend and hold harmless {name} and its subsidiaries, affiliates, partners, officers, directors, employees, and agents (collectively, “{name} Entities”) from all claims, whether actual or alleged (collectively, “Claims”), that arise out of or in connection with a breach of these Terms of Service, use of the Services, including any content you submit, post, transmit, modify or otherwise make available through the Services, and/or any violation of law and/or the rights of any third party. You are solely responsible for defending any Claim against a {name} Entity, subject to such {name} Entity’s right to participate with counsel of its own choosing, at its own expense, and for payment of all judgments, settlements, damages, losses, liabilities, costs, and expenses, including reasonable attorneys’ fees, resulting from all Claims against a {name} Entity, provided that you will not agree to any settlement that imposes any obligation or liability on a {name} Entity without its prior express written consent.</p>
        </Box>

        <Box>
          <strong>8. DISCLAIMER OF WARRANTIES</strong>
          <p>THE SERVICES, INCLUDING ALL INFORMATION AND CONTENT ON OR OTHERWISE RELATED IN ANY WAY TO THE SERVICES, THIS WEBSITE OR ANY THIRD-PARTY WEBSITE, PRODUCT, OR SERVICE LINKED TO OR FROM THIS WEBSITE, ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TIMELINESS, AND NONINFRINGEMENT. IN ADDITION, {name.toUpperCase()} DOES NOT WARRANT AGAINST DEFECTS IN ANY VEHICLE, AND DOES NOT MAKE ANY WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING REPRESENTATIONS, PROMISES, OR STATEMENTS AS TO THE CONDITION, FITNESS, OR MERCHANTABILITY OF ANY VEHICLE OR SERVICE. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM {name.toUpperCase()} OR THROUGH OR LINKED FROM THE SERVICES SHALL CREATE ANY WARRANTY EXPRESS OR IMPLIED. {name} is not responsible for making repairs to any vehicle. If you have complaints or concerns about defects or repairs, please contact the dealer, seller, or manufacturer directly.</p>
        </Box>

        <Box>
          <strong>9. LIMITATION OF LIABILITY; WAIVER</strong>
          <p>IN NO EVENT SHALL THE {name.toUpperCase()} ENTITIES BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE OF DATA, INFORMATION, AND/OR CONTENT, OR OTHER INTANGIBLE LOSSES ARISING OUT OF, OR IN CONNECTION WITH THE SERVICES, INCLUDING ALL INFORMATION AND CONTENT ON OR OTHERWISE RELATED IN ANY WAY TO THIS WEBSITE OR ANY THIRD-PARTY WEBSITE, PRODUCT, OR SERVICE LINKED TO OR FROM THIS WEBSITE, OR ANY VEHICLE REFERENCED THEREIN. WITHOUT LIMITING THE FOREGOING, ANY INFORMATION AND CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR AND HEREBY WAIVE ANY AND ALL CLAIMS AND CAUSES OF ACTION WITH RESPECT TO ANY DAMAGE TO YOUR COMPUTER SYSTEM, INTERNET ACCESS, DOWNLOAD OR DISPLAY DEVICE, OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH INFORMATION AND CONTENT. YOUR SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH ANY SERVICE AND/OR THIS WEBSITE IS TO STOP USING THE SERVICE AND/OR WEBSITE.</p>
          <p>YOU HEREBY IRREVOCABLY WAIVE ANY CLAIM (WHETHER FOR INJURY, ILLNESS, DAMAGE, LIABILITY AND/OR COST) AGAINST THE {name.toUpperCase()} ENTITIES ARISING OUT OF YOUR USE OF OR INABILITY TO USE, OR IN CONNECTION WITH, THE SERVICES, INCLUDING ANY INFORMATION AND CONTENT ON THIS WEBSITE OR ANY THIRD-PARTY WEBSITE, PRODUCT, OR SERVICE LINKED TO OR FROM THIS WEBSITE, INCLUDING ANY CONTENT YOU PROVIDE TO THIRD PARTIES (INCLUDING PERSONALLY IDENTIFIABLE INFORMATION).</p>
        </Box>
        <Box>
          <strong>10. EXCLUSIONS AND LIMITATIONS</strong>
          <p>SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN IMPLIED WARRANTIES, OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.</p>
        </Box>
        <Box>
          <strong>11. NOTICE</strong>
          <p>{name} may provide you with notices by email, regular mail, SMS, MMS, text message, postings on the Services/website, or other reasonable means now known or hereafter developed. You acknowledge and agree that {name} will have no liability associated with or arising from your failure to maintain and supply {name} with accurate contact information about yourself, including your failure to receive important information and updates about the Services or this website.</p>
        </Box>
        <Box>
          <strong>12. COPYRIGHT OR INTELLECTUAL PROPERTY COMPLAINTS</strong>
          <p>{name} respects the intellectual property of others. If you believe that your work has been copied in a way that constitutes copyright infringement, or your intellectual property rights have been violated, please provide us with the following information:</p>
          <ul>
            <li>a physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property right;</li>
            <li>a description of the copyrighted work or other intellectual property that you claim has been infringed or violated;</li>
            <li>a description of where the material that you claim is infringing is located on our website;</li>
            <li>your address, telephone number, and email address;</li>
            <li>a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright or intellectual property owner, its agent, or the law;</li>
            <li>a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright or intellectual property owner or authorized to act on the copyright or intellectual property owner's behalf.</li>
          </ul>
        </Box>
        <Box>
          <strong>13. GENERAL INFORMATION</strong>
          <p>These Terms of Service may be amended from time to time without notice in {name}’s sole discretion. Any changes to the Terms of Service will be effective immediately upon the posting of the revised Terms of Service on this website. The Terms of Service, including any agreements and terms incorporated by reference herein, constitute the entire agreement between you and {name} and govern your use of the Services, including this website, and supersede any prior version of these Terms of Service between you and {name} with respect to the Services. You agree that no agency relationship is created between you and {name} as a result of these Terms of Service or your access to and/or use of the Services. The failure of {name} to exercise or enforce any right or provision of the Terms of Service shall not constitute a waiver of such right or provision. If any provision of the Terms of Service is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of the Terms of Service remain in full force and effect. You agree that, except as otherwise expressly provided in these Terms of Service, there shall be no third-party beneficiaries to these Terms of Service. The headings in these Terms of Service are for convenience only and have no legal or contractual effect. As used in these Terms of Service, the word “including” is a term of enlargement meaning “including without limitation” and does not denote exclusivity, and the words “will,” “shall,” and “must” are deemed to be equivalent and denote a mandatory obligation or prohibition, as applicable. All definitions apply both to their singular and plural forms, as the context may require.</p>
        </Box>

      </Container>
    </Layout>
  );
};

export default TermsPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        shortName
      }
    }
  }
`
